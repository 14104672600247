import React, { Suspense, lazy } from 'react'
import i18n from './i18n'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import LoadingSpinner from 'components/LoadingSpinner'

import { I18nextProvider } from 'react-i18next'

const Login = lazy(() => import('pages/Login'))
const HomeLayout = lazy(() => import('pages/HomeLayout'))
const PrivateRoute = lazy(() => import('pages/PrivateRoute'))

const App = () => {
  return (
    <Router>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route caseSensitive path='/login' element={<Login />} />
            <Route
              path='/*'
              element={<PrivateRoute component={HomeLayout} />}
            />
          </Routes>
        </Suspense>
      </I18nextProvider>
    </Router>
  )
}

export default App
