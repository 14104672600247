import React from 'react'
import ReactDOM from 'react-dom/client'
import 'normalize.css'
import 'antd/dist/antd.min.css'
import 'styles/index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
