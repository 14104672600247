import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
/* ---- English Lang Translations ---- */
import common_en from 'translations/en/common.json'
import key_door from 'translations/en/key-door.json'
import worker_en from 'translations/en/worker.json'
import contractor_en from 'translations/en/Contractor.json'
import mc_en from 'translations/en/mc.json'
import buildings_en from 'translations/en/building.json'
import permission_en from 'translations/en/permission.json'
import errors_en from 'translations/en/errors.json'
import staff_en from 'translations/en/staff.json'

/* ---- Japanese translations ---- */
import common_jp from 'translations/jp/common.json'
import key_door_jp from 'translations/jp/key-door.json'
import worker_jp from 'translations/jp/worker.json'
import contractor_jp from 'translations/jp/Contractor.json'
import mc_jp from 'translations/jp/mc.json'
import buildings_jp from 'translations/jp/building.json'
import permission_jp from 'translations/jp/permission.json'
import errors_jp from 'translations/jp/errors.json'
import staff_jp from 'translations/jp/staff.json'

i18n
  // .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: 'jp',
    fallbackLng: 'en',
    // whitelist: ["en", "jp"],
    debug: true,
    languages: ['jp', 'en'],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: common_en,
        keydoorcompany: key_door,
        worker: worker_en,
        contractor: contractor_en,
        mc: mc_en,
        buildings: buildings_en,
        permission: permission_en,
        errors: errors_en,
        staff: staff_en,
      },
      jp: {
        common: common_jp,
        keydoorcompany: key_door_jp,
        worker: worker_jp,
        contractor: contractor_jp,
        mc: mc_jp,
        buildings: buildings_jp,
        permission: permission_jp,
        errors: errors_jp,
        staff: staff_jp,
      },
    },
  })

export default i18n
